import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Chip, Tooltip, IconButton, Grow, Zoom } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const stocks = gql`
  query stockData {
    quote(symbol: "MSFT") {
      c
      h
      l
      o
      pc
      t
    }
  }
`;
interface StockData {
  companyName: string;
  symbol: string;
  priceAtJan31st: number;
  currentMarketPrice: number;
  percentChange: string;
  averagePrice2019: number;
  upwardPotential: number;
}

interface EnhancedTableHeadProps {
  classes: {};
  numSelected: number;
  onRequestSort: (event: any, property: any) => void;
  onSelectAllClick: () => void;
  order: "asc" | "desc";
  orderBy: string;
  rowCount: number;
  createSortHandler: (property: any) => any;
}

// Generate Order Data
const createData = ({
  companyName,
  symbol,
  priceAtJan31st,
  currentMarketPrice,
  averagePrice2019,
  upwardPotential,
  percentChange
}: StockData): StockData => {
  return {
    companyName,
    symbol,
    priceAtJan31st,
    currentMarketPrice,
    averagePrice2019,
    upwardPotential,
    percentChange
  };
};

const rows = [
  createData({
    companyName: "Disney",
    symbol: "DIS",
    priceAtJan31st: 138.31,
    currentMarketPrice: 142.59,
    averagePrice2019: 123,
    upwardPotential: 12,
    percentChange: "-3.09"
  }),
  createData({
    companyName: "Disney",
    symbol: "DIS",
    priceAtJan31st: 138.31,
    currentMarketPrice: 142.59,
    averagePrice2019: 123,
    upwardPotential: 12,
    percentChange: "-3.09"
  }),
  createData({
    companyName: "Disney",
    symbol: "DIS",
    priceAtJan31st: 138.31,
    currentMarketPrice: 142.59,
    averagePrice2019: 123,
    upwardPotential: 12,
    percentChange: "-3.09"
  }),
  createData({
    companyName: "Disney",
    symbol: "DIS",
    priceAtJan31st: 138.31,
    currentMarketPrice: 142.59,
    averagePrice2019: 123,
    upwardPotential: 12,
    percentChange: "-3.09"
  })
];

// const descendingComparator = (a, b, orderBy) => {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// };

// const getComparator = (order, orderBy) => {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// };

// const stableSort = (array, comparator) => {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// };

const headCells = [
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Company Name"
  },
  { id: "symbol", numeric: false, disablePadding: true, label: "Symbol" },
  {
    id: "priceAtJan31st",
    numeric: true,
    disablePadding: true,
    label: "Price At Jan 31st"
  },
  {
    id: "currentMarketPrice",
    numeric: true,
    disablePadding: true,
    label: "Current Market Price"
  },
  {
    id: "averagePrice2019",
    numeric: true,
    disablePadding: true,
    label: "Average Price 2019"
  },
  {
    id: "upwardPotential",
    numeric: true,
    disablePadding: true,
    label: "Upward Potential"
  },
  {
    id: "percentChange",
    numeric: true,
    disablePadding: false,
    label: "% Change"
  },
  {
    id: "removeFromWL",
    numeric: true,
    disablePadding: false,
    label: "Remove"
  }
];

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };
};

const WatchList = ({
  classes,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  order,
  orderBy,
  rowCount,
  createSortHandler
}: EnhancedTableHeadProps) => {
  return (
    <>
      <Table size="medium">
        <TableHead>
          <TableRow>
            {headCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  // onClick={createSortHandler(headCell.companyName)}
                >
                  {headCell.label}
                  {/* {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null} */}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.companyName}>
              <TableCell>{row.companyName}</TableCell>
              <TableCell>
                <Chip
                  variant="outlined"
                  label={row.symbol}
                  component="a"
                  href="#chip"
                  clickable
                />
              </TableCell>
              <TableCell align="right">{row.priceAtJan31st}</TableCell>
              <TableCell align="right">{row.currentMarketPrice}</TableCell>
              <TableCell align="right">{row.averagePrice2019}</TableCell>
              <TableCell align="right">{row.upwardPotential}</TableCell>
              <TableCell align="right">{row.percentChange}</TableCell>
              <TableCell align="right">
                <Tooltip
                  title="Remove from Watch List"
                  placement="top"
                  TransitionComponent={Zoom}
                >
                  <IconButton aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
export default WatchList;
