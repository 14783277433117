import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const stocks = gql`
  query stockData {
    candles(
      symbol: "MSFT"
      resolution: "60"
      from: 1580482852
      to: 1580504452
    ) {
      c
      h
      l
      o
      s
      v
    }
  }
`;

const Dashboard = () => {
  const { loading, error, data } = useQuery(stocks);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{`Error: ${error.message}`}</p>;

  return (
    <div>
      <div>
        <ul>
          <li>Close: {data.candles.c}</li>
          <li>High: {data.candles.h}</li>
          <li>Low: {data.candles.l}</li>
          <li>Open: {data.candles.o}</li>
          <li>Time: {data.candles.s}</li>
          <li>Time: {data.candles.v}</li>
        </ul>
      </div>
    </div>
  );
};
export default Dashboard;
