import React from "react";

const Screener = () => {
  return (
    <div style={{ color: "#fff" }}>
      <span>Screen</span>
    </div>
  );
};
export default Screener;
