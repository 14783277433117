import React, { useState } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import NavContainers from "./components/NavContainers";
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
  const [theme, setTheme] = useState({
    palette: {
      type: "dark"
    }
  });
  const toggleDarkTheme = () => {
    let newPaletteType = theme.palette.type === "light" ? "dark" : "light";
    setTheme({
      palette: {
        type: newPaletteType
      }
    });
  };

  const muiTheme = createMuiTheme(theme as any);

  return (
    <Router>
      <ThemeProvider theme={muiTheme}>
        <NavContainers onToggleDark={toggleDarkTheme} />
      </ThemeProvider>
    </Router>
  );
};

export default App;
